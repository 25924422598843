<template>
  <div id="VoucherOverview" class="pb-1">
    <div id="VoucherOverview-inner">
      <div id="homePageHeader" v-if="dealerJSON.config.header_image">
        <img :src="'https://' + dealerJSON.config.header_image"/>
      </div>
      <div id="overviewIntro" v-if="dealerJSON.config.intro_text">
        <div id="overviewIntro-innerWrapper" class="pt-6 pl-4 pr-4 pb-2 ml-3 mr-3">
          <div id="overviewIntroText" class="pt-6 pl-3 pr-3 pb-6 font-weight-bold">
            {{IntroText}}
          </div>
        </div>
      </div>
      <!-- Filter, Sort & Search Box -->
      <VoucherFilter :class="{'pt-4' : !dealerJSON.config.intro_text}"
      :showVoucherCategory.sync="showVoucherCategory" :sortOption.sync="sortOption"
      :showTiles.sync="showTiles" :showSearchField.sync="showSearchField"
      :searchQuery.sync="searchQuery" @mobile-filter-height="mobileFilterHeight"
      :disableFilter="disableFilter" :isCategoryPage="isCategoryPage"
      :categoryPageData="categoryPageData" />
      <!-- Overview Title -->
      <div v-if="categoryTitle"><H1 class="pl-4 pr-4 pb-2">{{ categoryTitle }}</H1></div>
      <!-- Search Overview Filtered -->
      <div class="d-flex flex-row justify-start align-start flex-wrap voucher-wrapper" v-if="dealerJSON">
        <div v-for="(voucher, idx) in searchFilterVoucher" :key="idx" class="align-self-stretch" :class="{'voucher-tile' : showTiles, 'voucher-full' : showTiles == false}">
          <Voucher :voucherObject="voucher" :voucherBackgroundColor="voucherColors(voucher)[0]" :voucherTextColor="voucherColors(voucher)[1]" :tileLayout="showTiles" />
        </div>
      </div>
    </div>
    <!-- Arrow button that appears bottom right after scrolling down more than 20+ pixels -->
    <div class="scroll-arrow">
      <transition name="scrollTo">
        <v-btn aria-label="Scroll up" v-scroll="onScroll" v-show="showScrollButton" color="black" dark fab @click="toTop">
          <v-icon aria-hidden="true" size="30">mdi-chevron-up</v-icon>
        </v-btn>
      </transition>
    </div>
  </div>
</template>

<script>
import Voucher from '@/components/overview/Voucher.vue';
import VoucherFilter from '@/components/overview/Filter.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'VoucherOverview',
  components: {
    Voucher, VoucherFilter
  },
  props: {
    availableCategories: Array,
    disableFilter: Boolean,
    isCategoryPage: Boolean,
    categoryTitle: String
  },
  data(){
    return {
      showSearchField:false,
      showSortField: false,
      sortOption: '',
      searchQuery:'',
      voucherFilterOn:false,
      showVoucherCategory:[],
      showTiles:true,
      offsetTop: 0,
      showScrollButton:false,
      IntroText: '',
      categoryPageData: null
    }
  },
  watch: {
    searchQuery: function() {
      // We watch this data variable for when the value gets changed to null. This cannot be null or else the search function will Error out
      // This happens when you use the clear icon after filling in the search box. Vuetify applies the null value to searchQuery after clearing the search box.
      if (this.searchQuery === null) {
        this.searchQuery = '';
      }
    },
    availableCategories: function() {
      if (this.availableCategories.length == 1) {
        for (var i = 0; i < this.dealerJSON.categories.length;i++) {
          // if ID's match and text is not empty
          if (this.availableCategories == this.dealerJSON.categories[i].id && this.dealerJSON.categories[i].text != null) {
            this.IntroText = this.dealerJSON.categories[i].text;
          } else { // if text is empty, show homepage introText anyway
            this.IntroText = this.dealerJSON.config.intro_text;
          }

          // if we're at a category specific page, hence length == 1
          if (this.availableCategories.length == 1 && this.availableCategories == this.dealerJSON.categories[i].id ) {
            this.categoryPageData = this.dealerJSON.categories[i];
          }
        }
      } else {
        // if doesnt match, show homepage introText
        this.IntroText = this.dealerJSON.config.intro_text;
      }
    }
  },
  computed: {
    ...mapState({ // ... = making it possible to work with local computed properties like "searchFilterVoucher" as seen below
      dealerJSON: state => state.dealerJSON, // get data from store
      dealerConfigData: state => state.dealerConfigData // get data from store
    }),
    searchFilterVoucher() {
      var newArray = [];
      var filteredArray = [];

      // if the search box is empty
      if (this.searchQuery !== '' && this.searchQuery.length >= 1) {
        var _vue = this; // can't normally use 'this' within filter function. hence this var.

        // creation of a new Array
        newArray = this.dealerJSON.data.filter(function (el) {
          // before pressing enter in searchBox, search on this query
          if(_vue.searchQuery != null) {
            // Match the searchQuery with voucher_header (case insensitiive) or (||) match the searchQuery with voucher_description (also case insensitiive)
            return el.voucher_header.toLowerCase().match(_vue.searchQuery.toLowerCase()) ||
            el.voucher_description.toLowerCase().match(_vue.searchQuery.toLowerCase())
          }
        })
        // when searchFilterVoucher() starts, we show all categories. User has to filter by disabling each categorie. But this only works on the first attempt at searching. Doing a search afterwards wont re-enable all categories...
        _vue.showVoucherCategory = this.availableCategories; // value in this.availableCategories comes from mounted state in page view.
        // ^ instead of passing/copying data, here both data variables get edited if user clicks categories. But this is not an unwanted action. After refresh page, this gets resetted.

        // After we have a match to the searchword(s), we filter the results through categories
        filteredArray = newArray.filter(item => {
          // return item if it has visible property set to 1.
          if (this.disableFilter == false) {
            if (item.visible == 1) {
              return this.showVoucherCategory.includes(item.category_id);
            }
          } else {
            return this.showVoucherCategory.includes(item.category_id);
          }
        });
      }
      else if (this.availableCategories.length === 1) {
        // if you directly approach the category page, show only the vouchers of that category
        newArray = this.dealerJSON.data.filter(item => {
          if (this.disableFilter == false) {
            if (item.visible == 1) {
              return this.availableCategories.includes(item.category_id);
            }
          } else {
            return this.availableCategories.includes(item.category_id);
          }
        });
        filteredArray = newArray; // we do nothing special here, just take over exact value so we can move on out of this if/else condition
      }
      else if (this.dealerJSON.categories.length > this.showVoucherCategory.length && this.showVoucherCategory.length > 0) {
        // situation: when you click on a category label in the filter
        // if the JSON category length is longer than the categories we want to show. Of course, length has to be bigger than 0.

        // match each voucher in the JSON with the categories we want to see through category_id
        newArray = this.dealerJSON.data.filter(item => {
          // return item if it has visible property set to 1.
          if (this.disableFilter == false) {
            if (item.visible == 1) {
              return this.showVoucherCategory.includes(item.category_id);
            }
          } else {
            return this.showVoucherCategory.includes(item.category_id);
          }
        });
        filteredArray = newArray; // we do nothing special here, just take over exact value so we can move on out of this if/else condition
      }
      else {
        // show everything when no other condition has been met
        newArray = this.dealerJSON.data.map(x => x);
        // After we have a match to the searchword(s), we filter the results through categories
        filteredArray = newArray.filter(item => {
          // return item if it has visible property set to 1.
          if (this.disableFilter == false) {
            if (item.visible == 1) {
              return item;
            }
          } else {
            return item
          }
        });
      }

      // if a sortoption has been picked, sort the vouchers depending on choice
      if (this.sortOption !== '') {
        filteredArray = this.sortVouchers(filteredArray);
      }
      this.toTop(); // scroll to top of page when typing search words.
      // return Array to computed method
      return filteredArray;
    }
  },
  mounted() {
    // check viewport width, then decide if all vouchers will be shown in tiles or not.
    if (document.documentElement.clientWidth < 600) {
      this.showTiles = false;
    }
  },
  methods: {
    sortVouchers(arr) {
      if (this.sortOption === 'alphabet-ascending') {
        arr.sort(function(a,b) {
          if (a.voucher_header < b.voucher_header) {
            return -1;
          }
          if (a.voucher_header > b.voucher_header) {
            return 1;
          }
          return 0;
        });
      }
      if (this.sortOption === 'alphabet-descending') {
        arr.sort(function(a,b) {
          if (a.voucher_header < b.voucher_header) {
            return 1;
          }
          if (a.voucher_header > b.voucher_header) {
            return -1;
          }
          return 0;
        });
      }
      return arr;
    },
    showVoucher(object) {
      // go through all available categories first
      for (var i = 0; i < this.dealerJSON.categories.length;i++) {
        // check if category of current object matches one of the available categories
        if (object.category_id === this.dealerJSON.categories[i].id) {
          // if current matched category is active, return value true to this function.
          return true;
        }
      }
    },
    voucherColors(object) {
      // go through the categories
      for (var i = 0; i < this.dealerJSON.categories.length;i++) {
        if (object.category_id === this.dealerJSON.categories[i].id) {
          // apply found color to this.categoryColor
          var backgroundColor = this.dealerJSON.categories[i].background_color;
          var textColor = this.dealerJSON.categories[i].text_color;
          return [backgroundColor, textColor];
        }
      }
    },
    onScroll(e) {
      // Listener for scrolling
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.showScrollButton = top > 20; // button shows when scrolled past 20px vertically.
    },
    toTop() {
      // Scroll to top of page in the following manner:
      var options = {
        duration: 500,
        offset: 0,
        easing: 'linear'
      }
      this.$vuetify.goTo(0, options)
    },
    mobileFilterHeight(appBarHeight) {
      // get height of the filter that takes in space after closing/opening (takes a bit of time, hence the setTimeout)
      document.getElementById("VoucherOverview").style.padding = appBarHeight + "px 0 0"; // add padding to top
      // Scroll to top just incase user gets scrolled down because of this function
      this.toTop; // call this.toTop() method
    }
  }
};
</script>

<style lang="scss">
  $app-bar-height:56px;
  #homePageHeader {
    display: none; // temporarily disabled, need to take a look at mobile view.
    img {
      width: 100%;
      max-width: 100%;
      display: block;
    }
  }
  #VoucherOverview {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    #VoucherOverview-inner {
      position: relative;
      z-index: 1;
    }
    // arrow that scrolls along your page and allows you to jump to top on click.
    .scroll-arrow {
      position: fixed;
      z-index: 2;
      bottom: 20px;
      right:20px;
      opacity: 0.5;
      transition: opacity 0.5s;
      &:hover {
        opacity: 1;
      }
    }
  }
  #overviewIntro {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    #overviewIntro-innerWrapper {
      #overviewIntroText {
        font-size: 19px;
        text-align: center;
        background-color: #dfe4e8;
        //border:1px solid #ccc;
      }
      #overviewIntroTextShape {
        width: 100%;
        .t-shape {
          background: linear-gradient(to right bottom, #dfe4e8 50%, transparent 50%);
          width: 50%;
          height: 40px;
        }
        .t-left {
          left: 0px;
          transform: scaleX(-1);
        }
        .t-right {
          right: 0px;
        }
      }
    }
  }
  // Voucher Component (conditional class on top of component): voucher as full-width
  .voucher-full {
    width: 100%;
  }
  // Voucher Component (conditional class on top of component): voucher as a tile
  .voucher-tile {
    width: 50%;
    position: relative;
    .voucher-inner-wrapper {
      margin: 0 auto;
    }
  }
  // scrollTo transition for button that appears when you scroll down, click function lets you scroll all the way up again
  .scrollTo-enter-active, .scrollTo-leave-active {
    transition: opacity .5s;
  }
  .scrollTo-enter, .scrollTo-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  @media only screen and (min-width:600px) {
    #VoucherOverview {
      padding-top: 0 !important; // reset padding to 0
    }
    #overviewIntro {
      #overviewIntro-innerWrapper {
        #overviewIntroText {
          padding-left: 15% !important;
          padding-right: 15% !important;
        }
      }
    }
  }
  @media only screen and (max-width: 900px) {
    // Voucher Component (conditional class on top of component): voucher as a tile
    .voucher-tile {
      width: 100%;
      .voucher-tile-wrapper {
        display: flex;
        justify-content: center;
      }
      .voucher-tile-inner-wrapper {
        max-width: 440px;
      }
    }
  }
  @media only screen and (max-width:767px) {
    // Voucher Component (conditional class on top of component): voucher as a tile

    #overviewIntroTextShape .t-shape {
      height: 30px !important;
    }
    #overviewIntroText {
      font-size: 16px !important;
    }
  }
  @media only screen and (max-width:599px) {
    #homeIntro-innerWrapper {
      padding-bottom: 40px !important;
    }
    #VoucherOverview {
      padding-top: $app-bar-height + 12;
    }
    // arrow that scrolls along your page and allows you to jump to top on click.
    .scroll-arrow {
      display: none;
    }

    #overviewIntro #overviewIntro-innerWrapper {
      padding-top: 0 !important;
      padding-bottom: 16px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
</style>