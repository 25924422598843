<template>
  <div id="homePage" class="app-bar-padding-top footer-space">
    <!-- top bar -->
    <CustomAppBar v-if="dealerJSON" @MainMenuClick="$emit('toggleMainMenu')" v-on="$listeners" />
    <!-- Overview vouchers -->
    <Overview v-if="dealerJSON" :availableCategories="availableCategories" :disableFilter="false"/>
    <!-- Footer -->
    <Footer v-if="dealerJSON"></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import Overview from '@/components/overview/Overview.vue'
import CustomAppBar from '@/components/bars/CustomAppBar.vue';
import Footer from '@/components/bars/Footer.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'home',
  components: {
    CustomAppBar,
    Overview,
    Footer
  },
  data() {
    return {
      availableCategories:[]
    }
  },
  mounted() {
    sessionStorage.setItem('pOrigin', 'home'); // save page origin to session storage
    for (var i = 0; i < this.dealerJSON.categories.length; i++) {
      // for use in VoucherFilter component as a prop, but as array. Taken from Home.vue where all categories of the environment gets pushed in a seperate array called 'allCategories'
      this.availableCategories.push(this.dealerJSON.categories[i].id);
    }
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
    dealerConfigData: state => state.dealerConfigData // get data from store
  })
}
</script>