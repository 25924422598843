<template>
  <v-item-group multiple id="VoucherOverview-itemgroup" class="mt-0 mr-3 mb-0 ml-3" v-if="dealerJSON">
    <!--
    #######################
    ### DESKTOP FILTER
    #######################
    -->
    <div id="filterDesktop" class="d-none d-sm-block voucher-filter filter-desk mb-2" :class="{'mt-4': disableFilter}">
      <v-card-title class="pb-0 mb-3" v-if="!disableFilter">
        Filter
        <v-icon aria-hidden="true" class="ml-1">mdi-filter</v-icon>
      </v-card-title>
      <div class="voucher-filter-controls d-flex justify-space-between">
        <!-- START CATEGORY FILTER DESKTOP -->
        <div class="voucher-filter-category d-flex flex-wrap" v-if="!disableFilter">
          <v-btn :aria-label="category.category_name" @click="toggleCategory(category)" v-for="(category, idx) in showCategories(dealerJSON.categories)" :key="idx" class="mr-3 mb-3 font-weight-bold category-button text-capitalize" :class="{active: activeCategory(category)}">
            {{category.category_name}}
            <!-- circle color -->
            <v-icon aria-hidden="true" small class="ml-2" :color="category.background_color" v-show="activeCategory(category) === true">mdi-circle</v-icon>
            <v-icon aria-hidden="true" small class="ml-2" :color="category.background_color" v-show="activeCategory(category) === false || activeCategory(category) === undefined">mdi-circle-outline</v-icon>
          </v-btn>
        </div>
        <!-- END CATEGORY FILTER DESKTOP -->
        <!-- START BACK TO HOME BUTTON -->
        <div v-if="disableFilter && (categoryPageData == null || categoryPageData.show_back_to_home == 1)" class="voucher-filter-category d-flex flex-wrap">
          <v-btn aria-label="Naar de homepagina" to="/" class="mr-3 mb-3 font-weight-bold back-button">
            <v-icon aria-hidden="true" small class="mr-2">mdi-arrow-left-circle</v-icon>
            Naar de homepagina
          </v-btn>
        </div>
        <!-- END BACK TO HOME BUTTON -->
        <!-- START ICONS -->
        <v-item-group multiple v-if="dealerJSON" class="d-flex filter-icons">
          <v-btn v-if="categoryPageData == null || categoryPageData.show_sorting == 1" aria-label="Sorteren" icon class="mr-3 mb-3" @click="toggleSorting('desk')">
            <v-icon aria-hidden="true" color="black">mdi-sort</v-icon>
          </v-btn>
          <v-btn v-if="categoryPageData == null || categoryPageData.show_mobile_layout == 1" aria-label="Voucher weergave" icon class="mr-3 mb-3 showTiles" @click="$emit('update:showTiles', !showTiles)">
            <v-icon aria-hidden="true" v-show="showTiles === true">mdi-view-list</v-icon>
            <v-icon aria-hidden="true" v-show="showTiles === false">mdi-view-grid</v-icon>
          </v-btn>
          <v-btn v-if="categoryPageData == null || categoryPageData.show_search == 1" aria-label="Zoeken" icon class="mr-3 mb-3 search-button" @click="$emit('update:showSearchField', !showSearchField)">
            <v-icon aria-hidden="true" color="black">mdi-magnify</v-icon>
          </v-btn>
        </v-item-group>
        <!-- END ICONS -->
      </div>
    </div>
    <!-- START SORT BAR DESK-->
    <transition name="searchField">
      <div id="sortField-desk" class="flex-wrap sortField-wrapper" v-show="showSortField">
        <h3 class="mr-2">Sorteer op:</h3>
        <v-radio-group class="ma-0" v-model="sortValue" @change="$emit('update:sortOption', sortValue)" row><!-- on change emit the value to parent -->
          <v-radio value="alphabet-ascending" color="black"><template v-slot:label><div style="color:#000000;">Titel A-Z</div></template></v-radio>
          <v-radio value="alphabet-descending" color="black"><template v-slot:label><div style="color:#000000;">Titel Z-A</div></template></v-radio>
          <!--<v-radio value="createdate-ascending" color="black"><template v-slot:label><div style="color:#000000;">Nieuwste eerst</div></template></v-radio>
          <v-radio value="createdate-descending" color="black"><template v-slot:label><div style="color:#000000;">Oudste eerst</div></template></v-radio>-->
          <v-icon aria-label="Sluiten" @click="toggleSorting('desk')" color="black">mdi-close-circle</v-icon>
        </v-radio-group>
      </div>
    </transition>
    <!-- END SORT BAR DESK -->
    <!-- START SEARCH ZOEK BAR DESK-->
    <transition name="searchField">
      <div id="searchField-desk" class="flex-wrap searchField-wrapper" v-show="showSearchField">
        <v-text-field ref="searchFieldDesk" @keydown.enter="confirmSearch()" class="searchField pb-3" label="Zoeken" v-model="searchValue" @input="$emit('update:searchQuery', searchValue)" outlined clearable clear-icon="mdi-close-circle"></v-text-field>
      </div>
    </transition>
    <!-- END SEARCH ZOEK BAR DESK -->
    <!--
    #######################
    ### MOBILE FILTER
    #######################
    -->
    <div id="filterMobile" class="voucher-filter filter-mobile d-flex flex-wrap justify-space-between d-sm-none" ref="filterMobile"
    v-if="categoryPageData == null || categoryPageData.show_back_to_home == 1 || categoryPageData.show_sorting == 1 || categoryPageData.show_mobile_layout == 1 || categoryPageData.show_search == 1">
      <!-- START CATEGORY FILTER MOBILE -->
      <div class="voucher-filter-category" v-if="!disableFilter">
        <v-expansion-panels accordion>
          <v-expansion-panel :elevation="0">
            <v-expansion-panel-header class="pa-0 filter-header" @click="mobileFilterHeight()">
              <div>
                Filter<v-icon aria-hidden="true" class="ml-1">mdi-filter</v-icon>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-btn :aria-label="category.category_name" @click="toggleCategory(category)" v-for="(category, idx) in showCategories(dealerJSON.categories)" :key="idx" class="mr-3 mb-3 font-weight-bold category-button" :class="{active: activeCategory(category)}">
                {{category.category_name}}
                <!-- circle color -->
                <v-icon aria-hidden="true" small class="ml-2" :color="category.background_color" v-show="activeCategory(category) === true">mdi-circle</v-icon>
                <v-icon aria-hidden="true" small class="ml-2" :color="category.background_color" v-show="activeCategory(category) === false || activeCategory(category) === undefined">mdi-circle-outline</v-icon>
              </v-btn>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <!-- END CATEGORY FILTER MOBILE -->
      <!-- START BACK TO HOME BUTTON MOBILE -->
      <div class="home-button-mobile" v-if="disableFilter && (categoryPageData == null || categoryPageData.show_back_to_home == 1)">
        <v-btn aria-label="Naar de homepagina" to="/" class="font-weight-bold category-button">
          <v-icon aria-hidden="true" small class="mr-2">mdi-arrow-left-circle</v-icon>
          Naar de homepagina
        </v-btn>
      </div>
      <!-- END BACK TO HOME BUTTON MOBILE -->
      <!-- START ICONS -->
      <v-item-group multiple v-if="dealerJSON" class="showTiles-wrapper pr-1">
        <v-btn v-if="categoryPageData == null || categoryPageData.show_sorting == 1" aria-label="Sorteren" icon class="showTiles" @click="mobileFilterHeight()">
          <v-icon aria-label="Toon sorteer opties" @click="toggleSorting('mobile')" color="white">mdi-sort</v-icon>
        </v-btn>
        <v-btn v-if="categoryPageData == null || categoryPageData.show_mobile_layout == 1" aria-label="Voucher weergave" icon class="showTiles" @click="$emit('update:showTiles', !showTiles)">
          <v-icon aria-label="Toon vouchers als lijst" v-show="showTiles === true">mdi-view-list</v-icon>
          <v-icon aria-label="Toon vouchers als grid" v-show="showTiles === false">mdi-view-grid</v-icon>
        </v-btn>
        <v-btn v-if="categoryPageData == null || categoryPageData.show_search == 1" aria-label="Zoeken" icon class="showTiles search-button" @click="mobileFilterHeight(), $emit('update:showSearchField', !showSearchField)">
          <v-icon aria-label="Zoeken" color="white">mdi-magnify</v-icon>
        </v-btn>
      </v-item-group>
      <!-- END ICONS -->
      <!-- START SORT BAR MOBILE-->
      <transition name="searchField">
        <div id="sortField-mobile" class="flex-wrap sortField-wrapper" v-show="showSortField">
          <h3 class="mr-2 subtitle-1" style="color:#ffffff;">Sorteer op:</h3>
          <v-icon aria-label="Sluiten" @click="toggleSorting('desk'), mobileFilterHeight()" dark class="sort-field-button-mobile">mdi-close-circle</v-icon>
          <v-radio-group v-model="sortValue" class="ma-0" @change="$emit('update:sortOption', sortValue)" row>
            <v-radio value="alphabet-ascending" dark><template v-slot:label><div style="color:#ffffff;" class="body-2">Titel A-Z</div></template></v-radio>
            <v-radio value="alphabet-descending" dark><template v-slot:label><div style="color:#ffffff;" class="body-2">Titel Z-A</div></template></v-radio>
            <!--<v-radio value="createdate-ascending" dark><template v-slot:label><div style="color:#ffffff;" class="body-2">Nieuwste eerst</div></template></v-radio>
            <v-radio value="createdate-descending" dark><template v-slot:label><div style="color:#ffffff;" class="body-2">Oudste eerst</div></template></v-radio>-->
          </v-radio-group>
        </div>
      </transition>
      <!-- END SORT BAR MOBILE -->
      <!-- START SEARCH ZOEK BAR MOBILE-->
      <transition name="searchField">
        <div id="searchField-mobile" class="flex-wrap searchField-wrapper" v-show="showSearchField === true">
          <v-text-field ref="searchFieldMobile" @keydown.enter="confirmSearch()" color="white" dark class="searchField" label="Zoeken" v-model="searchValue" @input="$emit('update:searchQuery', searchValue)" outlined clearable clear-icon="mdi-close-circle">a</v-text-field>
        </div>
      </transition>
      <!-- END SEARCH ZOEK BAR MOBILE -->
    </div>
  </v-item-group>
</template>

<script>
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'Voucher',
  props: {
    showVoucherCategory: Array,
    sortOption:String,
    showTiles: Boolean,
    searchQuery: String,
    showSearchField: Boolean,
    confirmedSearch: Boolean,
    confirmedSearchQuery: String,
    disableFilter: Boolean,
    isCategoryPage: Boolean,
    categoryPageData: Object
  },
  data(){
    return {
      showSortField: false,
      sortValue:'',
      searchValue:''
    }
  },
  watch: {
    showSearchField: function(value) {
      if (value === true) {
        this.$nextTick(() =>{
          this.$refs.searchFieldDesk.focus();
          this.$refs.searchFieldMobile.focus();
        });
      }
      else if (value === false) {
        this.$nextTick(() =>{
          this.$refs.searchFieldDesk.blur();
          this.$refs.searchFieldMobile.focus();
        });
      }
    }
  },
  created() {
    this.mobileFilterHeight();
  },
  methods: {
    activeCategory(object) {
      if (this.showVoucherCategory.indexOf(object.id) > -1) {
        return true
      }
    },
    showCategories(categoriesArray) {
      // we need to filter categories, because each category has a property "exclude_on_homepage" that can be 0 or 1.
      var filteredArray = [];
      filteredArray = categoriesArray.filter(item => {
        // return item if it is not excluded for homepage
        if (item.exclude_on_homepage == 0) {
          return item;
        }
      });
      return filteredArray;
    },
    toggleCategory(object) {
      // below is for checking which category is checked/unchecked
      if (this.showVoucherCategory.indexOf(object.id) > -1) {
        // if present, remove from array, acts as a toggle
        this.showVoucherCategory.splice(this.showVoucherCategory.indexOf(object.id), 1);
      } else {
        // if the value is not in array, add to this array
        this.showVoucherCategory.push(object.id);
      }
    },
    toggleSorting(device) {
      this.showSortField = !this.showSortField;
      return device;
    },
    confirmSearch() {
      this.$nextTick(this.$refs.searchFieldDesk.blur);
      this.$nextTick(this.$refs.searchFieldMobile.blur); //mobile
      this.$emit('callConfirmSearch'); // get parent confirmSearch
    },
    mobileFilterHeight() {
      var _vue = this;
      // get height of the filter that takes in space after closing/opening (takes a bit of time, hence the setTimeout)
      if (_vue.$refs.filterMobile) {
        setTimeout(function() {
          _vue.$emit('mobile-filter-height', _vue.$refs.filterMobile.clientHeight + 20); // get height in px of the filter component.
        }, 500);
      }
    }
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
  })
};
</script>

<style lang="scss">
// home button on mobile when approaching a category url directly. IE: /winter or /zomer
.home-button-mobile {
  min-height: 48px;
  display: flex;
  align-items: center;
}
// Voucher filter wrapper
.voucher-filter {
  // mobile view
  &.filter-mobile {
    min-height: 48px;
    .filter-header {
      max-width: 120px;
      min-height: 48px !important; // override
    }
    .v-expansion-panel {
      background-color: transparent!important;
      &:before {display: none!important;}
    }
    .voucher-filter-category {
      position: relative;
      z-index: 2;
    }
    // tile view switch
    .showTiles-wrapper {
      position: absolute; // was absolute
      right: 0;
      z-index: 4;
      //margin-left: auto;
      .showTiles {
        margin-top: 6px;
      }
    }
    // category buttons
    .category-button {
      height: 28px !important;
      font-size: 12px !important;
      padding: 0 12px !important;
      background-color: black !important;
      color: white !important;
      border:1px solid white;
      text-transform: capitalize;
      font-weight: normal !important;
    }
  }
}
/* Start Sort option */
#sortField-desk {
  padding: 0 20px;
  display: flex;
}
.sortField-wrapper {
  position: relative;
}
#sortField-mobile {
  float: left;
  width: 100%;
  .sort-field-button-mobile {
    position:absolute;
    right:0;
    top:4px;
  }
}
.filter-icons {
  margin-left: auto;
}
/* End Sort option */
// expansion panel = wrapper around filter buttons on mobile view
.v-expansion-panel-content > .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.category-button {
  &.active {
    opacity: 1;
  }
  opacity: 0.8;
}
// tiles
.showTiles {
  i {color: black!important;}
}
// scrollTo transition for vuejs to use as tag in template
.searchField-enter-active {
  animation: resize-in 0.25s;
}
.searchField-leave-active {
  animation: resize-in 0.2s reverse;
}
// search Field
.searchField-wrapper {
  width: 100%;
  //overflow: hidden;
  padding-top: 3px;
  .searchField {
    width: 100%;
  }
  // override to remove description tag
  .v-text-field__details {
    display: none;
  }
}
// animation for search field
@keyframes resize-in {
  0% {
    transform: scale(0);
    height: 0;
  }
  100% {
    transform: scale(1);
    height: auto;
  }
}

@media only screen and (min-width:600px) {
  // sort field
  .sortField-wrapper {
    &#sortField-mobile {
      display: none !important;
    }
  }
  .searchField-wrapper {
    // disable searchfield for mobile
    &#searchField-mobile {
      display: none !important;
    }
  }
}
@media only screen and (max-width:599px) {
  // sort field
  .sortField-wrapper {
    &#sortField-desk {
      display: none !important;
    }
  }
  // search field
  .searchField-wrapper {
    // disable searchfield for desktop
    &#searchField-desk {
      display: none !important;
    }
  }
  // Sort & Filter wrapper that can grow and resize (through JS). Only in mobile
  $app-bar-height:56px;
  .v-application div#VoucherOverview-itemgroup {
    margin-left: 0 !important;
    margin-right: 0 !important;
    //padding-top: $app-bar-height + 12;
    .filter-mobile {
      //background-color: #fafafa;
      background-color: black;
      position: fixed;
      top:$app-bar-height;
      width: 100%;
      padding: 0 16px 0;
      z-index: 5;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

      .filter-header,
      .filter-header i,
      .showTiles i {
        color: white !important;
      }
    }
  }
}
</style>