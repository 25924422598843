<template>
  <div class="voucher-inner-wrapper" :elevation="0">
    <!-- START TILE LAYOUT -->
    <div class="voucher-tile-wrapper pt-0 pl-4 pr-4 pb-7" v-if="tileLayout == true">
      <v-card class="voucher-tile-inner-wrapper pa-5" :style="{'backgroundColor': voucherBackgroundColor}" @click.prevent="openVoucherForm" :ripple="false" tile>
        <div class="voucher-text-bg">
          <img alt="" src="../../assets/voucher.png" :style="iconColor"/>
        </div>
        <div class="voucher-data-container">
          <div class="align-content-start text-left voucher-title voucher-title-height pb-1" :style="'color:' + voucherTextColor + ';'" v-html="voucherObject.voucher_header"></div>
          <div class="voucher-description voucher-description-height text-left voucher-text-color" :style="'color:' + voucherTextColor + ';'" v-html="voucherObject.voucher_description"></div>
          <div v-if="voucherObject.voucher_pay_off" class="voucher-sub-description text-left voucher-text-color pt-1" :style="'color:' + voucherTextColor + ';'" v-html="voucherObject.voucher_pay_off"></div>
          <!-- action label -->
          <div v-if="voucherObject.action_panel_text" class="voucher-action-label pt-3 pb-3 pl-3 pr-3" :style="'color:' + voucherObject.action_panel_text_color + ';background-color:' + voucherObject.action_panel_background_color + ';'" v-html="voucherObject.action_panel_text"></div>
          <!-- logo -->
          <div class="voucher-logo">
            <img alt="Logo" class="logo-icon" :src="'https://' + voucherObject.voucher_icon" :style="'max-width:' + dealerConfigData.dealer_logo.voucher_width + 'px;'"/>
          </div>
          <div class="download-icon">
            <img alt="Download" src="../../assets/download.svg" :style="iconColor"/>
          </div>
        </div>
      </v-card>
    </div>
    <!-- END TILE LAYOUT -->
    <!-- START FULL LAYOUT -->
    <div class="voucher-full-wrapper pt-0 pl-3 pr-3 pb-2" v-if="tileLayout == false">
      <v-card class="voucher-full-inner-wrapper" :style="{'backgroundColor': voucherBackgroundColor}" @click.prevent="openVoucherForm" :ripple="false" tile>
        <div class="voucher-full-content-wrapper d-flex justify-space-between pa-3">
          <div class="text-wrapper">
            <div class="align-content-start voucher-title voucher-title-height pt-0 pl-0 pr-4 pb-0" :style="'color:' + voucherTextColor + ';'">
              {{voucherObject.voucher_header}}
            </div>
            <div class="voucher-description voucher-description-height" :style="'color:' + voucherTextColor + ';'">
              {{voucherObject.voucher_description}}
            </div>
          </div>
          <!-- icons -->
          <v-card-actions class="flex-wrap justify-space-between voucher-actions">
            <img alt="Logo" class="voucher-icon logo-icon" :src="'https://' + voucherObject.voucher_icon" :style="'max-width:' + dealerConfigData.dealer_logo.voucher_width_flat + 'px;'"/>
            <img alt="Download" class="voucher-icon download-icon" src="../../assets/download.svg" :style="iconColor"/>
          </v-card-actions>
        </div>
      </v-card>
    </div>
    <!-- END FULL LAYOUT -->
  </div>
</template>

<script>
import {Color, Solver} from '@/assets/js/ColorSolver';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'Voucher',
  props: {
    voucherObject:Object, // passed a single voucherObject from parent
    voucherBackgroundColor:String,
    voucherTextColor:String,
    tileLayout: Boolean
  },
  data() {
    return {
        iconColor:''
    }
  },
  mounted() {
    const rgb = this.hexToRgb(this.voucherTextColor);
    if (rgb.length !== 3) {
      //console.log('Invalid format!');
      return;
    }
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    const result = solver.solve();
    this.iconColor = result.filter;
  },
  computed: {
    ...mapState({
      dealerConfigData: state => state.dealerConfigData // get data from store
    })
  },
  methods: {
    openVoucherForm() {
      this.$router.push({path: this.voucherObject.url_alias + '/stap1'}); // go to first step of voucher
    },
    hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
        : null;
    }
  }
};
</script>

<style lang="scss">
  // max-width on inner-wrapper in TILES LAYOUT
  .voucher-tile .voucher-inner-wrapper {
    max-width: 600px; //276px;
    height: 100%;
  }
  // inner wrapper
  .voucher-inner-wrapper {
    position: relative;
    z-index: 1;
    width: 100%; // high value in order to fill voucher, max width it 100% anyways
    // general styles
    .voucher-title {
      // override
      word-break: normal;
      font-size:1.75rem; //font-size:1.425rem;
      line-height: 1.875rem;
      font-weight: 300;
    }
    .voucher-description {
      // override
      font-size:1.75rem; //font-size:1.625rem;
      line-height: 1.875rem;
      font-weight: 900;
      overflow-wrap: normal;
    }
    // START TILE LAYOUT
    .voucher-tile-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      // START inner wrapper
      .voucher-tile-inner-wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 220px;
        min-height: 220px;
        //height: 100%;
        padding-bottom:88px;
        overflow: hidden;
        // inherit frame work border radius
        border-top-left-radius:inherit;
        border-top-right-radius:inherit;
        // all children in inner-wrapper
        &:hover {
          cursor: pointer;
        }
        > * {
          position: relative;
          z-index: 1;
        }
        .voucher-data-container {
          position: relative;
          padding: 0 60px 80px 0;
          height: 100%
        }
        .voucher-action-label {
          position: absolute;
          bottom:0;
          right:2px;
          font-size: 1.375rem;
          line-height: 1.75rem;
        }
        .voucher-logo {
          position: absolute;
          top:0;
          right:0;
        }
        // icons
        .download-icon {
          position: absolute;
          left: 0px;
          bottom: -3px;
          img {
            width:24px;
          }
        }
        .voucher-text-bg {
          position: absolute;
          bottom:0;
          left:0;
          width: 100%;
          opacity: 0.2;
          z-index: 0;
          img {
            position: absolute;
            bottom: -2px;
            left:0;
            width: 100%;
            max-width: 470px;
          }
        }
      } // END inner wrapper
    }
    // END TILE LAYOUT

    // START FULL WIDTH VOUCHER LAYOUT
    .voucher-full-wrapper {
      width: 100%;
      position: relative;
      .voucher-full-inner-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        // inherit frame work border radius
        border-top-left-radius:inherit;
        border-top-right-radius:inherit;
        border-bottom-left-radius:inherit;
        border-bottom-right-radius:inherit;
        .voucher-full-content-wrapper {
          // wraps title and description
          position: relative;
          z-index: 1;
          .text-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 70%;
            .voucher-title {
              align-self: center;
              margin-right: auto;
              width: 60%;
            }
            .voucher-description {
              align-self: center;
              width: 40%;
            }
          }
          // download button wrapper
          .voucher-actions {
            margin-left: auto;
            width: 30%;
            z-index: 1;
            padding: 0 8px 0 0;
            .voucher-icon {
              //max-width: 40px;
              width: 100%;
              // color is in /public/static/theme.css
            }
            .voucher-icon.logo-icon {
              margin-right: 4px;
            }
            .voucher-icon.download-icon {
              margin-left: auto;
              max-width:40px;
            }
          }
        }
      }
    }
    // END FULL WIDTH VOUCHER LAYOUT
  }
  @media only screen and (max-width:460px) {
    .voucher-full-wrapper {
      .voucher-full-content-wrapper {
        min-height: 58px;

        div.text-wrapper {
          width: 100% !important;
          div.voucher-title {
            font-size: 1rem !important;
            line-height: 1.1rem !important;
          }
          div.voucher-description {
            font-size: 0.875rem !important;
            line-height: 1rem !important;
          }
        }
        div.voucher-actions {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0.2;
          .voucher-icon.download-icon {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width:580px) {
    .voucher-full-wrapper {
      .voucher-full-content-wrapper {
        min-height: 58px;
        div.voucher-title {
          font-size: 1.1rem !important;
          line-height: 1.2rem !important;
        }
        .text-wrapper {
          .voucher-description {
            font-size: 1rem !important;
            line-height: 1.1rem !important;
          }
        }
        .voucher-actions {
          position: absolute;
          right:0;
          top:0;
          bottom:0;
        }
      }
    }
  }
  @media only screen and (max-width:767px) {
    .voucher-full-wrapper .voucher-actions .voucher-icon.logo-icon {
      display: none;
    }
    .voucher-full-wrapper {
      div.voucher-title {
        font-size: 1.4rem !important;
        padding-right: 11px !important;
      }
      .voucher-description {
        font-size: 1.1rem !important;
      }
    }
    .voucher-full-wrapper {
      .voucher-full-content-wrapper {
        .text-wrapper {
          width: 85% !important;
        }
        .voucher-actions {
          padding: 10px !important;
          flex-wrap: nowrap !important;
          width: 15% !important;
          img {
            max-width: 38px;
            width: 100%;
            padding: 4px;
          }
        }
      }
    }
  }
  @media only screen and (max-width:420px) {
    .voucher-tile .download-icon {
      display: none;
    }
    .voucher-tile .voucher-action-label {
      margin: 20px 0 0;
      position: relative !important;
      right: auto !important;
    }
    .voucher-tile .voucher-data-container {
      padding: 0 !important;
    }
    .voucher-tile .voucher-tile-inner-wrapper {
      min-height: 0 !important;
    }
  }
  @media only screen and (min-width:767px) {
    .voucher-full-wrapper {
      .voucher-actions {
        min-width: 180px;
        max-width: 200px;
      }
    }
  }
</style>
